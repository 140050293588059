import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
const EmployeeContext = createContext(null);
const EditEmployeeProvider = ({ children, initialValue }) => {
  const { control, handleSubmit, getValues, setValue,watch } = useForm({
    defaultValues: {},
  });
  const setValuesRecursive = (data, prefix = '') => {
    Object.entries(data).forEach(([key, value]) => {
      const fieldKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object' && !Array.isArray(value)) {
        setValuesRecursive(value, fieldKey);
      } else {
        setValue(fieldKey, value);
      }
    });
  };
  useEffect(()=> {
    if(initialValue){
        setValuesRecursive(initialValue)
    }
  },[initialValue])
  const [educationDoc, setEducationDoc] = useState([]);
  const [workDoc, setWorkDoc] = useState([]);
  return (
    <EmployeeContext.Provider
      value={[
        control,
        handleSubmit,
        setValue,
        getValues,
        educationDoc,
        setEducationDoc,
        workDoc,
        setWorkDoc,
        watch
      ]}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

export const useEmployeeContext = () => useContext(EmployeeContext);
export default EditEmployeeProvider;



{
    
}
