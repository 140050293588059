import React, { useEffect, useState, useRef } from "react";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { getMetaData } from "../api";
import { DownloadIcon } from "@heroicons/react/solid";
import { ROLE } from "../config";
import Spinner from "../components/loader/Spinner";
import { Button } from "../components/ui";
import UserPunches from "./WFH/UserPunches";
import PendingApproveLeaveList from "../components/dashboard/PendingApproveLeaveList.jsx";

const cookies = new Cookies();
function Dashboard() {
  const [role, setRole] = useState()
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    setRole(cookies.get(ROLE));
    if (cookies.get(ROLE) === "employee"){
      return
    }
    setIsLoading(true);
    getMetaData()
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="px-4">
          {cookies.get(ROLE) === "employee" ? (
            <>
              <UserPunches data={data} />
            </>
          ) : cookies.get(ROLE) === "consultant" ? (
            <div>
              <div className="grid grid-cols-4 gap-x-4">
                <div className="bg-orange-700 text-white text-center py-5 rounded">
                  <h3 className="text-lg font-semibold">
                    Other Leaves Available
                  </h3>
                  <h1 className=" text-2xl font-semibold">
                    {data?.pending_ol_leave}
                  </h1>
                </div>
                <div className="bg-orange-700 text-white text-center py-5 rounded">
                  <h3 className="text-lg font-semibold">
                    Discretionary Leaves Available
                  </h3>
                  <h1 className=" text-2xl font-semibold">
                    {data?.pending_dl_leave}
                  </h1>
                </div>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-4 gap-4">
              <div className="bg-orange-700 text-white text-center py-5 rounded">
                <Link to={"/leave-applied"}>
                  <h3 className="text-lg font-semibold">Pending Leaves Approvals</h3>
                  <h1 className=" text-2xl font-semibold">
                    {data?.pending_leave}
                  </h1>
                </Link>
              </div>
              <div className="bg-orange-700 text-white text-center py-5 rounded">
                <Link to={"/work-from-home-approval"}>
                  <h3 className="text-lg font-semibold">Pending WFH Approvals</h3>
                  <h1 className=" text-2xl font-semibold">
                    {data?.pending_wfh}
                  </h1>
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
      {role=='admin' && (
         <PendingApproveLeaveList />
      ) }
    </>
  );
}

export default Dashboard;
