import React, { useMemo, useState } from "react";
import Moment from "react-moment";
import usePut from "../../hooks/usePut";
import toast, { Toaster } from "react-hot-toast";
import spinner from "../../assets/images/spinner.gif";
import { Modal } from "@mui/material";
import { dateObjToTimestamp, numberWithComma } from "../../utility";
import { Button } from "../../components/ui";
import DatePicker from "react-datepicker";
import { cloneDeep } from "lodash";

let status = {
  1: "Payout Completed",
  2: "Payout in process",
  3: "Drafting Completed",
  4: "Draft in process",
  5: "Draft pending",
};
const CompanyPayroll = ({ data, filterData, handleCalculateSalary }) => {
  const currentDate = useMemo(() => new Date(), []);
  const draftPayRoll = usePut({
    url: "draftPayroll",
    onSuccess: () => {
      toast.success("Payroll Drafted Successfully");
      window.location.reload();
    },
  });
  const approvePayRoll = usePut({
    url: "processPayout",
    onSuccess: () => {
      toast.success("Payroll Drafted Successfully");
      window.location.reload();
    },
  });
  const handleUpdateStatus = (e, action) => {
    if (action === "draft") {
      let data = {
        month_year: new Date(filterData.month_and_year),
      };
      draftPayRoll.handleAuth.mutate(data);
    } else if (action === "approve") {
      let data = {
        month_year: new Date(filterData.month_and_year),
      };
      approvePayRoll.handleAuth.mutate(data);
    }
  };
  const processSalarySlip = usePut({
    url: "generateBulkSalarySlip",
    onSuccess: () => {
      toast.success("Salary Slip Processed Successfully");
      window.location.reload();
    },
    onError: () => {
      toast.error("There Was An Error While Generating Salary Slip");
    },
  });
  const deleteSalarySlip = usePut({
    url: "deleteSalaryPayout",
    onSuccess: () => {
      toast.success("Salary Slip Deleted Successfully");
      window.location.reload();
    },
    onError: () => {
      toast.error("There Was An Error While Deleting Salary Slip");
    },
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const generateSalarySlip = () => {
    let data = {
      month_year: new Date(filterData.month_and_year),
    };
    processSalarySlip.handleAuth.mutate(data);
  };
  const handleOpenDeleteSalarySlip = () => {
    setOpenDeleteModal(true);
  };
  const handleDeleteSalarySlip = () => {
    let data = {
      month_year: new Date(filterData?.month_and_year),
    };
    deleteSalarySlip.handleAuth.mutate(data);
  };
  const getDaysInMonth = (monthAndYear) => {
    const date = new Date(monthAndYear);
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const daysInMonth = getDaysInMonth(filterData?.month_and_year);

  // Code for changing payment date
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showEditPaymentDate, setShowEditPaymentDate] = useState(false);
  const [paymentDate, setPaymentDate] = useState();

  const { handleAuth: changeDateReq } = usePut({
    url: "changePaymentDate",
    onSuccess: () => {
      handleCalculateSalary?.(filterData);
      setShowEditPaymentDate(false);
      setShowConfirmation(false);
      toast.success("Payment date changed successfully")
    },
    onError: (err) => {
      console.log("err", err);
      toast.error(err?.response?.data?.message, { duration: 5000 });
      setShowConfirmation(false);
    },
  });

  const showEditView = (value) => {
    setPaymentDate(value);
    setShowEditPaymentDate(true);
  };

  const hideEditView = () => {
    setShowEditPaymentDate(false);
  };

  const savePaymentDate = () => {
    const value = cloneDeep(paymentDate);

    value.setHours(0, 0, 0, 0);

    changeDateReq.mutate({
      month_and_year: filterData.month_and_year?.toString(),
      payment_date: dateObjToTimestamp(value),
    });
  };

  return ( 
    <>
      <div className="px-5 mt-10">
        {(draftPayRoll.handleAuth.isLoading ||
          approvePayRoll.handleAuth.isLoading) && (
          <div className=" w-full h-full fixed top-0 left-0 flex items-center justify-center bg-[#ffffffbf] z-50">
            <img src={spinner} alt="spinner" className="w-20 h-20" />
          </div>
        )}
        <Toaster />
        <div className="grid grid-cols-8 gap-x-6">
          <div className="bg-gray-100 col-span-3 rounded-md p-[20px]">
            <div className=" text-sm">
              <span className="font-mm">Period:</span>{" "}
              <span className="font-semibold font-mm mr-1">
                <Moment date={filterData?.month_and_year} format="MMMM YYYY" />
              </span>
              |
              <span className="font-small ml-1">
                <span id="ember125" className="tooltip-container ember-view">
                  {daysInMonth} Base Days
                </span>
              </span>
            </div>
            {showEditPaymentDate ? (
              <div className="flex items-center mt-5 space-x-3">
                <DatePicker
                  selected={paymentDate}
                  name="date"
                  onChange={(value) => setPaymentDate(value)}
                  className="input"
                  dateFormat="dd/MM/yyyy"
                />
                <Button onClick={hideEditView} variant={"outline"}>
                  Cancel
                </Button>
                <Button onClick={() => setShowConfirmation(true)}>Save</Button>
              </div>
            ) : (
              <div className="flex items-center justify-between mt-5">
                <p className="text-sm ">
                  Payment Date:{" "}
                  <span className="font-semibold">
                    <Moment
                      date={new Date(data?.payment_date * 1000)}
                      format="DD MMMM YYYY"
                    />
                  </span>
                </p>

                <Button
                  className={" disabled:opacity-50 "}
                  disabled={
                    data?.processing_status === "1" ||
                    data?.processing_status === "2" ||
                    data?.processing_status === "4"
                  }
                  onClick={() =>
                    showEditView(new Date(data.payment_date * 1000))
                  }
                >
                  Change date
                </Button>
              </div>
            )}

            <div className="flex items-center gap-x-10 my-5">
              <div className="col-6">
                <h4 className=" text-xl font-semibold">
                  {data?.net_salary
                    ? numberWithComma(Math.round(data.net_salary))
                    : "----"}
                </h4>
                <div className=" uppercase text-sm text-gray-500">
                  EMPLOYEES' NET PAY
                </div>
              </div>
              <div className="col-6">
                <h4 className=" text-base font-semibold">Total Employees</h4>
                <div className=" uppercase text-sm text-gray-500">
                  {data?.total_employees
                    ? numberWithComma(Math.round(data.total_employees))
                    : "----"}
                </div>
              </div>
            </div>
            {data?.processing_status && (
              <span className=" text-orange-700 bg-orange-200 px-4 text-xs py-2 rounded mb-2 inline-block">
                {status[data?.processing_status]}
              </span>
            )}
            <div className=" gap-2 flex flex-wrap">
              {data?.processing_status === "3" ? (
                <>
                  <button
                    className=" bg-yellow-200 px-4 py-2 rounded text-sm"
                    onClick={(e) => handleUpdateStatus(e, "approve")}
                  >
                    Approve Payroll
                  </button>
                  <button
                    className=" bg-yellow-200 px-4 py-2 rounded text-sm"
                    onClick={(e) => handleUpdateStatus(e, "draft")}
                  >
                    Re-Draft Payroll
                  </button>
                </>
              ) : null}

              {/* {(data?.length <= 0 || !data?.total_employees) && ( */}
              {(data?.processing_status?.toString() === "5") && (
                <button
                  className=" bg-yellow-200 px-4 py-2 rounded text-sm"
                  onClick={(e) => handleUpdateStatus(e, "draft")}
                >
                  Draft Payroll
                </button>
              )}
            </div>
            <div className=" flex items-center space-x-5 mt-4">
              {data?.bulk_salary_slip_path && (
                <a href={data?.bulk_salary_slip_path} download>
                  Download Salary Slip
                </a>
              )}
              {data?.salary_slip_creation_status === "2" && (
                <span className=" text-yellow-700 bg-yellow-200 px-2 py-1 text-xs rounded">
                  Salary Generation in process
                </span>
              )}
              {(data?.salary_slip_creation_status === "3" ||
                !data?.salary_slip_creation_status) && (
                <button
                  className=" text-blue-600 font-semibold hover:underline text-sm"
                  onClick={generateSalarySlip}
                >
                  Generate Salary Slip
                </button>
              )}
              {data?.salary_slip_creation_status === "1" && (
                <button
                  className=" text-red-600 font-semibold hover:underline text-sm"
                  onClick={handleOpenDeleteSalarySlip}
                >
                  Delete Salary Slip
                </button>
              )}
            </div>
          </div>

          <div className="deductions-section col-span-2">
            <h4 className="font-xmedium font-semibold text-gray-600 text-lg underline">
              Taxes &amp; Deductions
            </h4>
            <table className="table noborder-table mt-2 w-full">
              <tbody>
                <tr className="">
                  <td className="py-1 text-sm payrun-label mr-5 text-gray-600">
                    Employee ESIC
                  </td>
                  <td className=" px-8 text-right text-sm font-semibold">
                    {data?.employee_esic
                      ? numberWithComma(Math.round(data.employee_esic))
                      : "----"}
                  </td>
                </tr>
                <tr className="">
                  <td className="py-1 text-sm payrun-label mr-5 text-gray-600">
                    Employee PF
                  </td>
                  <td className=" px-8 text-right text-sm font-semibold">
                    {data?.employee_epf
                      ? numberWithComma(Math.round(data.employee_epf))
                      : "----"}
                  </td>
                </tr>
                <tr className="">
                  <td className="py-1 text-sm payrun-label mr-5 text-gray-600">
                    Employer ESIC
                  </td>
                  <td className=" px-8 text-right text-sm font-semibold">
                    {data?.employer_esic
                      ? numberWithComma(Math.round(data.employer_esic))
                      : "----"}
                  </td>
                </tr>
                <tr className="">
                  <td className="py-1 text-sm payrun-label mr-5 text-gray-600">
                    Employer PF
                  </td>
                  <td className=" px-8 text-right text-sm font-semibold">
                    {data?.employer_epf
                      ? numberWithComma(Math.round(data.employer_epf))
                      : "----"}
                  </td>
                </tr>
                <tr className="">
                  <td className="py-1 text-sm payrun-label mr-5 text-gray-600">
                    Professional-Tax
                  </td>
                  <td className=" px-8 text-right text-sm font-semibold">
                    {data?.employee_professional_tax
                      ? numberWithComma(
                          Math.round(data.employee_professional_tax)
                        )
                      : "----"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Modal for payment date change confirmation */}
      <Modal open={showConfirmation} onClose={() => setShowConfirmation(false)}>
        <div className="w-[25%] bg-white rounded-md absolute left-1/2 -translate-x-1/2 top-7">
          <div className=" space-y-3 py-4">
            <div className=" px-4">
              <h2 className=" font-semibold">
                Are you sure you wish to change the date and re-process the
                drafts?
              </h2>
            </div>
            <div className=" space-x-3 px-4 flex justify-end">
              <button
                disabled={changeDateReq?.isLoading}
                className="bg-red-200 text-red-700 px-4 py-2 rounded"
                onClick={() => setShowConfirmation(false)}
              >
                Cancel
              </button>
              <button
                disabled={changeDateReq?.isLoading}
                className="border border-blue-500 px-4 py-2 rounded"
                onClick={savePaymentDate}
              >
                Yes
              </button>
            </div>
            {
              changeDateReq?.isLoading && <p className="px-4">
                Loading... 
              </p>
            }
          </div>
        </div>
      </Modal>
      {/* Modal for deletion */}
      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <div className="w-[25%] bg-white rounded-md absolute left-1/2 -translate-x-1/2 top-7">
          <div className=" space-y-3 py-4">
            <div className=" px-4">
              <h2 className=" font-semibold">
                Are you sure you want to delete the salary slip?
              </h2>
            </div>
            <div className=" space-x-3 px-4">
              <button
                className="bg-red-200 text-red-700 px-4 py-2 rounded"
                onClick={handleDeleteSalarySlip}
              >
                Delete
              </button>
              <button
                className="border border-blue-500 px-4 py-2 rounded"
                onClick={() => setOpenDeleteModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CompanyPayroll;
