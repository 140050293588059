import { EyeIcon, XIcon } from "@heroicons/react/solid";
import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import Pagination from "../../components/pagination";
import PrimaryLoader from "../../components/common/PrimaryLoader";
import { Toaster, toast } from "react-hot-toast";
import { getLeaveAppliactionList } from "../../api/employeeDetails";
import Modals from "../../components/modal/Modals";
import Moment from "react-moment";
import ZoomImage from "../../components/ZoomImage";
import { verifyLeaveApplication } from "../../api/leaveApplicationForm";
import spinner from "../../assets/images/spinner.gif";
import useGet from "../../hooks/read/useGet";
import { debounce } from "lodash";

function LeaveApplied() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [leaveList, setLeaveList] = useState([]);
  const [singleLeaveData, setSingleLeaveData] = useState({});
  const [isOpenVerificationModal, setIsOpenVerificationModal] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const initialParams = {
    page_no: searchParams.get('page_no') || 0,
    limit: searchParams.get('limit') || 10,
    search_query: searchParams.get('search_query') || ''
  };

  const { list, paramsObject, setGetListParams } = useGet({
    url: "leaveapplicationlist",
    initialData: initialParams,
    onSuccess: (data) => {
      setIsFetchingData(false);
    },
    onError: (error) => {
      setIsFetchingData(false);
      console.error("Error fetching leave applications:", error);
      toast.error("Error fetching leave applications. Please try again later.");
    },
  });

  const leaves = list.data?.data || [];

  useEffect(() => {
    const searchQuery = searchParams.get("search_query");
    const pageNumber = searchParams.get("page_no");
    const limit = searchParams.get("limit");

    // Only update the URL when search_query or pagination params are meaningful
    if (!searchQuery && (!pageNumber || pageNumber === "0")) {
      setSearchParams({}); // Clear the URL to `/leave-applied`
    } else if (!searchQuery) {
      setSearchParams({ page_no: pageNumber || 0, limit: limit || 10 });
    }
  }, [searchParams]);

  useEffect(() => {
   //  handleGetLeaveAppliactionList();
  }, [paramsObject]);

  const handleGetLeaveAppliactionList = () => {
    setIsFetchingData(true);
    getLeaveAppliactionList()
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          const filteredData = data.data.filter((item) =>
            item.employee_name
              .toLowerCase()
              .includes(paramsObject.search_query.toLowerCase())
          );
          setLeaveList(filteredData);
          setIsFetchingData(false);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          "Error fetching leave applications. Please try again later."
        );
        setIsFetchingData(false);
      });
  };

  const debouncedOnSearch = useRef(
    debounce((searchQuery) => {
      const updatedParams = {
        ...paramsObject,
        search_query: searchQuery,
        page_no: 0, // Reset to first page on search
      };
      setGetListParams(updatedParams);
      setSearchParams(updatedParams); // Update URL search params
    }, 700)
  ).current;

  const [searchQuery, setSearchQuery] = useState(paramsObject.search_query);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value); // Update the input field's state immediately
    debouncedOnSearch(value); // Trigger the debounced function
  };

  const handlePageClick = (val) => {
    const updatedParams = {
      ...paramsObject,
      page_no: val.selected,
    };
    setGetListParams(updatedParams);
    setSearchParams(updatedParams);
  };

  const handleGetSingleLeaveData = (id) => {
    setSingleLeaveData(leaves.find((data) => data.id === id));
    setIsOpenVerificationModal(true);
  };

  const handleVerifyLeave = (id, action) => {
    setIsLoading(true);
    verifyLeaveApplication(id, action)
      .then((res) => {
        if (res.status === 200) {
          handleGetLeaveAppliactionList();
          setIsOpenVerificationModal(false);
          setIsLoading(false);
          list.refetch();
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="flex items-center justify-between px-4 mb-10">
        <div className="w-[400px]">
          <input
            type="search"
            placeholder="Search By User Name..."
            name="search_query"
            className="input"
            value={searchQuery} // Bound to the immediate input state
            onChange={handleSearch}
          />
        </div>
      </div>
      <Toaster />
      {isFetchingData ? (
        <div className="w-16 h-16 mx-auto my-20">
          <PrimaryLoader />
        </div>
      ) : (
        <div className="bg-[color:var(--color3)]">
          {leaves.length > 0 ? (
            <div className="max-w-full overflow-x-auto px-4 mt-8">
              <table className="table-auto w-full">
                <thead>
                  <tr className="bg-[color:var(--color1)] text-center">
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4 border-l border-transparent">
                      User Name
                    </th>
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4 border-r border-transparent">
                      Type of leave
                    </th>
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4 border-r border-transparent">
                      From
                    </th>
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4 border-r border-transparent">
                      To
                    </th>
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4 border-r border-transparent">
                      Status
                    </th>
                    <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-4 lg:py-7 px-3 lg:px-4 border-r border-transparent">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {leaves.map((data, index) => {
                    const {
                      employee_name,
                      type_of_leave,
                      leave_from,
                      leave_to,
                      id,
                      status,
                    } = data;
                    return (
                      <tr key={index}>
                        <td className="text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                          {employee_name}
                        </td>
                        <td className="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-[#E8E8E8] capitalize">
                          {type_of_leave}
                        </td>
                        <td className="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-[#E8E8E8] capitalize">
                          <Moment unix format="DD/MM/yyyy">
                            {leave_from}
                          </Moment>
                        </td>
                        <td className="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-[#E8E8E8] capitalize">
                          {leave_to !== "0" ? (
                            <Moment unix format="DD/MM/yyyy">
                              {leave_to}
                            </Moment>
                          ) : (
                            "---"
                          )}
                        </td>
                        <td className="text-center border-b border-[#E8E8E8]">
                          {status === "1" ? (
                            <span className="bg-green-700 text-green-200 px-2 py-1 rounded-full text-xs">
                              Approved
                            </span>
                          ) : status === "2" ? (
                            <span className="bg-yellow-700 text-yellow-200 px-2 py-1 rounded-full text-xs">
                              Pending
                            </span>
                          ) : (
                            <span className="bg-red-700 text-red-200 px-2 py-1 rounded-full text-xs">
                              Rejected
                            </span>
                          )}
                        </td>
                        <td className="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-r border-[#E8E8E8]">
                          <div className="flex justify-center space-x-1">
                            <div
                              className="cursor-pointer"
                              onClick={() => handleGetSingleLeaveData(id)}
                            >
                              <EyeIcon className="h-6 w-6 text-primary hover:text-secondary" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center text-dark py-20">
              No leave applications found.
            </div>
          )}
          <div className="mt-10 flex justify-center">

            <Pagination
              lengthofItems={list.data?.total_count}
              limit={paramsObject.limit}
              currentPage={paramsObject.page_no}
              onPageChange={handlePageClick}
            />
          </div>
        </div>
      )}
      <Modals show={isOpenVerificationModal}>
        <div className=" divide-y">
          <div className="modalHead pb-3">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold">Leave Verification</h2>
              <div
                className="w-6 h-6 cursor-pointer"
                onClick={() => setIsOpenVerificationModal(false)}
              >
                <XIcon className="w-full h-full" />
              </div>
            </div>
          </div>
          <div className="modalBody py-3 space-y-4">
            <div>
              <label htmlFor="employeeName" className="label">
                Employee Name :{" "}
              </label>
              <span className="text-lg font-semibold">
                {" "}
                {singleLeaveData?.employee_name}
              </span>
            </div>
            <div>
              <label htmlFor="leave_dates" className="label">
                Leave Dates :{" "}
              </label>
              <span className="text-lg font-semibold inline-block mx-2 bg-gray-300 px-4 py-2 rounded">
                <span>
                  {" "}
                  {singleLeaveData?.type_of_leave === "CL"
                    ? "On : "
                    : "From : "}{" "}
                </span>
                <small>
                  <Moment unix format="DD/MM/yyyy">
                    {singleLeaveData?.leave_from}
                  </Moment>
                </small>
              </span>
              {singleLeaveData?.type_of_leave === "CL" ? null : (
                <span className="text-lg font-semibold inline-block ml-2 bg-gray-300 px-4 py-2 rounded">
                  <span> To : </span>
                  <small>
                    {singleLeaveData?.leave_to !== "0" ? (
                      <Moment unix format="DD/MM/yyyy">
                        {singleLeaveData?.leave_to}
                      </Moment>
                    ) : (
                      "---"
                    )}
                  </small>
                </span>
              )}
            </div>
            <div>
              <label htmlFor="employeeName" className="label">
                Reason for leave :{" "}
              </label>
              <span className="text-lg font-semibold">
                {singleLeaveData?.reason}
              </span>
            </div>
            {(singleLeaveData?.type_of_leave === "SL" ||
              singleLeaveData?.type_of_leave === "OL" ||
              singleLeaveData?.type_of_leave === "other") &&
              singleLeaveData?.file_list &&
              singleLeaveData?.file_list.length > 0 && (
                <div className="Medical Certificate">
                  <label htmlFor="employeeName" className="label">
                    Medical Certificate :{" "}
                  </label>
                  <div className="grid grid-cols-4 gap-4">
                    {singleLeaveData?.file_list.map((src, idx) => {
                      const { file_name, document_media_type } = src;
                      return (
                        <>
                          {document_media_type === "pdf" ? (
                            <a
                              href={file_name}
                              target="_blank"
                              rel="noopener noreferrer"
                              className=" text-center py-2 px-2 rounded bg-gray-400 text-gray-700"
                            >
                              View PDF
                            </a>
                          ) : (
                            <ZoomImage key={idx} className="h-full">
                              <img
                                src={file_name}
                                alt="dummy"
                                className="border-2 border-black w-full h-full"
                              />
                            </ZoomImage>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
          </div>
          <div className="modalFoot pt-3">
            {isLoading ? (
              <div className="w-8 h-8 mx-auto">
                <img src={spinner} alt="spinner" className="w-full h-full" />
              </div>
            ) : (
              <div className=" space-x-4">
                <button
                  className="px-8 py-2 rounded bg-[color:var(--color1)] text-white"
                  onClick={() =>
                    handleVerifyLeave(singleLeaveData?.id, "APPROVE")
                  }
                >
                  Verify
                </button>
                <button
                  className="px-8 py-2 rounded border"
                  onClick={() =>
                    handleVerifyLeave(singleLeaveData?.id, "REJECT")
                  }
                >
                  Reject
                </button>
              </div>
            )}
            {errorMessage && (
              <p className=" font-semibold text-red-700 text-lg text-center pt-3">
                Error: {errorMessage}
              </p>
            )}
          </div>
        </div>
      </Modals>
    </>
  );
}

export default LeaveApplied;
